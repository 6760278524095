import React, { useState, useCallback, useEffect } from "react";
import Header from "../components/ui/Header";
import Footer from "../components/ui/Footer";
import { Desktop, Mobile } from "../hooks/mediaQuery";
import Aside from "../components/ui/Aside";
import "../assets/css/Home.css";
import usersImage from "../assets/images/home_icon_users.svg";
import exchangeImage from "../assets/images/home_icon_exchange.svg";
import globalImage from "../assets/images/home_icon_global.svg";
import mainWalletImage from "../assets/images/main_wallet.png";
import mainTradeImage from "../assets/images/main_trade.png";
import bitcoinImage from "../assets/images/BTCUSDT.svg";
import ethereumImage from "../assets/images/ETHUSDT.svg";
import bnbImage from "../assets/images/BNBUSDT.svg";
import solImage from "../assets/images/SOLUSDT.svg";
import xrpImage from "../assets/images/XRPUSDT.svg";
import dogeImage from "../assets/images/DOGEUSDT.svg";
import shibImage from "../assets/images/SHIBUSDT.svg";
import bchImage from "../assets/images/BCHUSDT.svg";
import cardfirst from "../assets/images/card-pic-1.png";
import cardsecond from "../assets/images/card-pic-2.png";
import cardthird from "../assets/images/card-pic-3.png";
import stepFirst from "../assets/images/step-1.png";
import stepSecond from "../assets/images/step-2.png";
import stepThird from "../assets/images/step-3.png";
import stepFourth from "../assets/images/step-4.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// 가격 데이터를 위한 타입 정의
interface PriceData {
  [key: string]: string;
}

// 변동폭 데이터를 위한 타입 정의
interface PercentageChangeData {
  [key: string]: string;
}

const Home = () => {
  const hostUrl = process.env.REACT_APP_HOST_URL;
  const navigate = useNavigate();
  const [prices, setPrices] = useState<PriceData>({});
  const [percentageChanges, setPercentageChanges] = useState<PercentageChangeData>({});
  const [isMenuOpen, setMenuOpen] = useState(false);

  // 관심 있는 특정 거래 쌍들
  const trendingSymbols: string[] = [
    "BTCUSDT",
    "ETHUSDT",
    "BNBUSDT",
    "SOLUSDT",
    "XRPUSDT",
    "DOGEUSDT",
    "SHIBUSDT",
    "BCHUSDT",
  ];

  const centerItems = [
    {
      title: "Buy & Sell Crypto",
      content:
        "We realize ideas from simple to complex, everything becomes easy to use and reach the most potential customers.",
      button: "Buy crypto",
      image: cardfirst,
      url: "/buy-crypto",
    },
    {
      title: "Trade Assets",
      content:
        "We realize ideas from simple to complex, everything becomes easy to use and reach the most potential customers.",
      button: "Trade now",
      image: cardsecond,
      url: "/exchange",
    },
    {
      title: "Learn crypto",
      content:
        "We realize ideas from simple to complex, everything becomes easy to use and reach the most potential customers.",
      button: "Learn now",
      image: cardthird,
      url: "/learn-crypto",
    },
  ];

  const stepItems = [
    {
      title: "Connect Vibex",
      image: stepFirst,
      content:
        "Effortlessly connect to Vibex and access a seamless trading experience like never before. Your gateway to the crypto world starts here.",
    },
    {
      title: "Connect Wallet",
      image: stepSecond,
      content:
        "Securely link your crypto wallet to manage your assets with ease. Enjoy safe and swift transactions in an intuitive interface.",
    },
    {
      title: "Start Trading",
      image: stepThird,
      content:
        "Dive into the dynamic world of cryptocurrency trading. Utilize our advanced tools and real-time data to make informed decisions and execute trades swiftly.",
    },
    {
      title: "Earn Money",
      image: stepFourth,
      content:
        "Maximize your earnings with our optimized trading platform. Track your gains, strategize your investments, and grow your wealth efficiently.",
    },
  ];

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const response = await axios.get("https://api.binance.com/api/v3/ticker/24hr");
        const data = response.data;

        // 관심 있는 거래 쌍들의 가격 및 24시간 변동폭 필터링
        const filteredPrices: PriceData = data
          .filter((ticker: any) => trendingSymbols.includes(ticker.symbol))
          .reduce((acc: PriceData, ticker: any) => {
            acc[ticker.symbol] = parseFloat(ticker.lastPrice).toFixed(5);
            return acc;
          }, {});

        const filteredChanges: PercentageChangeData = data
          .filter((ticker: any) => trendingSymbols.includes(ticker.symbol))
          .reduce((acc: PercentageChangeData, ticker: any) => {
            acc[ticker.symbol] = parseFloat(ticker.priceChangePercent).toFixed(2);
            return acc;
          }, {});

        setPrices(filteredPrices);
        setPercentageChanges(filteredChanges);
      } catch (error) {
        console.error("Failed to fetch prices", error);
      }
    };

    fetchPrices();

    const intervalId = setInterval(fetchPrices, 30000); // 30초마다 업데이트

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    requestUserInfo();
  }, []);

  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  function goJoin() {
    window.location.href = "/register";
  }

  function goMarkets() {
    window.location.href = "/markets";
  }

  function getCrptoImage(symbol: string): string {
    if (symbol === "BTCUSDT") {
      return bitcoinImage;
    } else if (symbol === "ETHUSDT") {
      return ethereumImage;
    } else if (symbol === "BNBUSDT") {
      return bnbImage;
    } else if (symbol === "SOLUSDT") {
      return solImage;
    } else if (symbol === "XRPUSDT") {
      return xrpImage;
    } else if (symbol === "DOGEUSDT") {
      return dogeImage;
    } else if (symbol === "SHIBUSDT") {
      return shibImage;
    } else if (symbol === "BCHUSDT") {
      return bchImage;
    }
    return "";
  }

  /**
   * api
   */
  const requestUserInfo = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/user/info";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.data.code == 0) {
      } else {
        //console.log(response.data);
        if (response.data.code == 40000) {
          localStorage.removeItem("accessToken");
          navigate("/login", { replace: true });
        }
      }
    } catch (error) {}
  };

  return (
    <div className="w-full h-full">
      <Header openMenu={openMenu} closeMenu={closeMenu} />
      <Mobile>
        <Aside isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
      </Mobile>
      <Desktop>
        <article className="w-full max-w-[1280px] h-auto mx-auto px-[80px]">
          <section className="relative my-[200px]">
            <div className="flex flex-col items-start justify-center">
              <div className="relative max-w-[540px]">
                <h1 className="font-extrabold text-[3.5rem] text-white">Buy & Sell</h1>
                <h2 className="mt-[-20px] font-extrabold text-[3.5rem] text-white">
                  crypto in minutes
                </h2>
                <span className="text-xl text-[var(--t-color-desc)]">
                  Trade Bitcoin, Ethereum, USDT, and the top altcoins on the legendary crypto asset
                  exchange.
                </span>
              </div>
              <button
                className="mt-5 px-7 py-3 bg-[var(--t-color-point)] text-white rounded-[100px] cursor-pointer"
                onClick={goJoin}
              >
                Get Started Now
              </button>
            </div>
            <div className="absolute top-[-200px] right-[-180px]">
              <img src={mainWalletImage} alt="" className="w-[900px]" />
            </div>
          </section>
          <section className="mt-[300px]">
            <div className="flex flex-row items-center justify-start">
              <h1 className="font-extrabold text-[2.5rem] text-white">Market Trend</h1>
              <button className="ml-5 px-3 py-2 text-white border border-white rounded-[100px] hover:bg-[var(--t-color-point)] cursor-pointer">
                View More
              </button>
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full mt-10">
                <thead>
                  <tr className="text-[var(--t-color-desc)]">
                    <th className="px-4 py-2 text-left border-b-2 border-gray-300">Name</th>
                    <th className="px-4 py-2 text-center border-b-2 border-gray-300">Price</th>
                    <th className="px-4 py-2 text-center border-b-2 border-gray-300">24h Change</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(prices).map(([symbol, price]) => (
                    <tr key={symbol} className="text-white">
                      <td className="flex items-center justify-start px-4 py-5 font-bold">
                        <img src={getCrptoImage(symbol)} alt={symbol} className="w-6 h-6 mr-2" />
                        {symbol}
                      </td>
                      <td className="px-4 py-5 font-bold text-center">{price}</td>
                      <td className="px-4 py-5 font-bold text-center">
                        {percentageChanges[symbol]}%
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>
        </article>
      </Desktop>
      <Mobile>
        <article className="w-full h-auto px-5 mx-auto">
          <section className="relative mt-[80px]">
            <div className="flex flex-col items-start justify-center">
              <div className="relative">
                <h1 className="font-extrabold text-[2.5rem] text-white leading-[50px]">
                  Buy & Sell
                </h1>
                <h2 className="font-extrabold text-[2.5rem] text-white  leading-[50px]">
                  crypto in minutes
                </h2>
                <h3 className="mt-2 text-base text-[var(--t-color-desc)]">
                  Trade Bitcoin, Ethereum, USDT, and the top altcoins on the legendary crypto asset
                  exchange.
                </h3>
              </div>
              <button
                className="mt-5 px-7 py-3 bg-[var(--t-color-point)] text-white rounded-[100px] cursor-pointer"
                onClick={goJoin}
              >
                Get Started Now
              </button>
              <div className="relative w-full mt-10">
                <img src={mainWalletImage} alt="" />
              </div>
            </div>
          </section>
          <section className="mt-5">
            <div className="flex flex-row items-center justify-between">
              <h1 className="font-extrabold text-[1.5rem] text-white">Market Trend</h1>
              <button
                className="ml-5 px-4 py-2 text-white border border-white rounded-[100px] hover:bg-[var(--t-color-point)] cursor-pointer"
                onClick={goMarkets}
              >
                View More
              </button>
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full mt-10">
                <thead>
                  <tr className="text-[var(--t-color-desc)]">
                    <th className="px-4 py-2 text-left border-b-2 border-gray-300">Name</th>
                    <th className="px-4 py-2 text-left border-b-2 border-gray-300">Price</th>
                    <th className="px-4 py-2 text-left border-b-2 border-gray-300">24h Change</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(prices).map(([symbol, price]) => (
                    <tr key={symbol} className="text-white">
                      <td className="flex items-center justify-start px-4 py-5 text-sm font-bold">
                        <img src={getCrptoImage(symbol)} alt={symbol} className="w-6 h-6 mr-2" />
                        {symbol}
                      </td>
                      <td className="px-4 py-5 text-sm font-bold text-center">{price}</td>
                      <td className="px-4 py-5 text-sm font-bold text-center">
                        {percentageChanges[symbol]}%
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>
        </article>
      </Mobile>
      <Desktop>
        <article>
          <section className="w-full mt-20 py-[100px] bg-[#18191D]">
            <h1 className="text-[2.5rem] font-extrabold text-white text-center">Become a crypto</h1>
            <h1 className="text-[2.5rem] font-extrabold text-white text-center">
              trader in seconds
            </h1>
            <h1 className="mt-5 text-xl text-[var(--t-color-desc)] text-center">
              We've got everything you need to start trading.
            </h1>
            <div className="w-full h-[450px] max-w-[1280px] mx-auto mt-10 flex flex-row justify-center items-center">
              {centerItems.map((item) => (
                <div
                  key={item.title}
                  className="w-[30%] h-full ml-5 px-[32px] py-[50px] text-center transition-all ease-in-out bg-[#23262F] rounded-xl"
                >
                  <div>
                    <img src={item.image} alt="" className="w-[150px] mx-auto" />
                  </div>
                  <h1 className="mt-5 text-[1.5rem] font-bold text-white">{item.title}</h1>
                  <h2 className="mt-5 text-sm text-[var(--t-color-desc)]">{item.content}</h2>
                  <button className="mt-5 px-7 py-3 text-[0.8rem] text-white border border-white rounded-[100px] shadow-md hover:bg-[var(--t-color-point)] hover:text-white cursor-pointer">
                    View More
                  </button>
                </div>
              ))}
            </div>
          </section>
          <section className="w-full py-[100px]">
            <div className="w-full h-[450px] max-w-[1280px] mx-auto flex flex-row items-center justify-center">
              <div className="flex flex-col items-center justify-start">
                <h1 className="text-[2.5rem] font-extrabold text-white">Trade anywhere</h1>
                <h1 className="mt-5 text-xl text-[var(--t-color-desc)] text-center">
                  Anytime, anywhere. Trade crypto on your terms.
                </h1>
              </div>
              <div>
                <img src={mainTradeImage} alt="" className="w-[500px]" />
              </div>
            </div>
          </section>
          <section className="w-full mt-20 py-[100px] bg-[#18191D]">
            <h1 className="text-[2.5rem] font-extrabold text-white text-center">How it works</h1>
            <h1 className="max-w-[500px] mt-5 mx-auto text-xl text-[var(--t-color-desc)] text-center">
              Our platform continuously monitors various cryptocurrency exchanges to provide you
              with the most up-to-date market trends. Data is fetched and updated in real-time,
              ensuring you have the latest information at your fingertips
            </h1>
            <div className="w-full h-[450px] max-w-[1280px] mx-auto mt-10 flex flex-row justify-center items-center">
              {stepItems.map((item: any, index: number) => (
                <div
                  key={item.title}
                  className="w-[30%] h-full ml-5 px-[32px] py-[50px] text-center"
                >
                  <div>
                    <img src={item.image} alt="" className="w-[150px] mx-auto" />
                  </div>
                  <h1 className="mt-5 text-sm text-[var(--t-color-desc)]">STEP-{index + 1}</h1>
                  <h1 className="mt-5 text-[1.5rem] font-bold text-white">{item.title}</h1>
                  <h2 className="mt-5 text-sm text-[var(--t-color-desc)]">{item.content}</h2>
                </div>
              ))}
            </div>
          </section>
        </article>
      </Desktop>
      <Mobile>
        <article>
          <section className="w-full mt-20 py-[100px] bg-[#18191D]">
            <h1 className="text-[1.5rem] font-extrabold text-white text-center">Become a crypto</h1>
            <h1 className="text-[1.5rem] font-extrabold text-white text-center">
              trader in seconds
            </h1>
            <h1 className="mt-5 text-xl text-[var(--t-color-desc)] text-center">
              We've got everything you need to start trading.
            </h1>
            <div className="flex flex-col items-center justify-center w-full h-auto px-10 mt-5">
              {centerItems.map((item) => (
                <div
                  key={item.title}
                  className="w-full h-auto mt-5 py-[50px] text-center transition-all ease-in-out bg-[#23262F] rounded-xl"
                >
                  <div>
                    <img src={item.image} alt="" className="w-[150px] mx-auto" />
                  </div>
                  <h1 className="mt-5 text-[1.5rem] font-bold text-white">{item.title}</h1>
                  <h2 className="mt-5 text-sm text-[var(--t-color-desc)]">{item.content}</h2>
                  <button className="mt-5 px-7 py-3 text-[0.8rem] text-white border border-white rounded-[100px] shadow-md hover:bg-[var(--t-color-point)] hover:text-white cursor-pointer">
                    View More
                  </button>
                </div>
              ))}
            </div>
          </section>
          <section className="w-full py-[100px]">
            <div className="flex flex-row items-center justify-center w-full h-auto px-10">
              <div className="flex flex-col items-center justify-start">
                <h1 className="text-[1.5rem] font-extrabold text-white">Trade anywhere</h1>
                <h1 className="mt-5 text-[1rem] text-[var(--t-color-desc)] text-center">
                  Anytime, anywhere. Trade crypto on your terms.
                </h1>
              </div>
              <div className="w-[50%]">
                <img src={mainTradeImage} alt="" className="w-[500px]" />
              </div>
            </div>
          </section>
          <section className="w-full py-[100px] bg-[#18191D]">
            <h1 className="text-[1.5rem] font-extrabold text-white text-center">How it works</h1>
            <h1 className="mt-5 mx-auto text-[1rem] text-[var(--t-color-desc)] text-center">
              Our platform continuously monitors various cryptocurrency exchanges to provide you
              with the most up-to-date market trends. Data is fetched and updated in real-time,
              ensuring you have the latest information at your fingertips
            </h1>
            <div className="flex flex-col items-center justify-center w-full h-auto mt-10">
              {stepItems.map((item: any, index: number) => (
                <div key={item.title} className="w-full h-auto px-[32px] py-[50px] text-center">
                  <div>
                    <img src={item.image} alt="" className="w-[150px] mx-auto" />
                  </div>
                  <h1 className="mt-5 text-sm text-[var(--t-color-desc)]">STEP-{index + 1}</h1>
                  <h1 className="mt-5 text-[1.5rem] font-bold text-white">{item.title}</h1>
                  <h2 className="mt-5 text-sm text-[var(--t-color-desc)]">{item.content}</h2>
                </div>
              ))}
            </div>
          </section>
        </article>
      </Mobile>
      {/*
        <article className="welcome">
        <section>
          <h1>
            Trade your <strong>crypto</strong>
            <span>When you want</span>
            <span>What you want</span>
          </h1>
          <h2>
            <strong>24 Hours Available</strong>
            <span>Stable chart reflection with updated mechanism.</span>
            <span>Various data being provided.</span>
          </h2>
          <a href="/options">start</a>
        </section>
      </article>
      <Desktop>
        <article className="flex flex-row items-center justify-center py-10">
          <section className="flex flex-col items-center justify-center w-auto h-auto">
            <img src={usersImage} alt="" className="w-[60px] h-[60px]" />
            <h1 className="text-[2rem] text-[var(--t-color-point)]">45+</h1>
            <h2 className="text-sm font-bold text-white">Millions Users' Top Choice</h2>
          </section>
          <section className="flex flex-col justify-center items-center w-auto h-auto ml-[100px]">
            <img src={exchangeImage} alt="" className="w-[60px] h-[60px]" />
            <h1 className="text-[2rem] text-[var(--t-color-point)]">$2,975,991,493</h1>
            <h2 className="text-sm font-bold text-white">24h Trading Volume</h2>
          </section>
          <section className="flex flex-col justify-center items-center ml-[100px]">
            <img src={globalImage} alt="" className="w-[60px] h-[60px]" />
            <h1 className="text-[2rem] text-[var(--t-color-point)]">700+</h1>
            <h2 className="text-sm font-bold text-white">Prime Virtual Assets</h2>
          </section>
        </article>
      </Desktop>
      <Mobile>
        <article className="flex flex-col items-start justify-center px-10 py-5">
          <section className="flex flex-row items-center justify-center w-auto h-auto">
            <img src={usersImage} alt="" className="w-[60px] h-[60px]" />
            <div className="flex flex-col ml-5">
              <h1 className="text-[1.5rem] text-[var(--t-color-point)]">45+</h1>
              <h2 className="text-sm font-bold text-white">Millions Users' Top Choice</h2>
            </div>
          </section>
          <section className="flex flex-row items-center justify-center w-auto h-auto mt-3">
            <img src={exchangeImage} alt="" className="w-[60px] h-[60px]" />
            <div className="flex flex-col ml-5">
              <h1 className="text-[1.5rem] text-[var(--t-color-point)]">$2,975,991,493</h1>
              <h2 className="text-sm font-bold text-white">24h Trading Volume</h2>
            </div>
          </section>
          <section className="flex flex-row items-center justify-center w-auto h-auto mt-3">
            <img src={globalImage} alt="" className="w-[60px] h-[60px]" />
            <div className="flex flex-col ml-5">
              <h1 className="text-[1.5rem] text-[var(--t-color-point)]">700+</h1>
              <h2 className="text-sm font-bold text-white">Prime Virtual Assets</h2>
            </div>
          </section>
        </article>
      </Mobile>
      <div className="features">
        <h2 className="mt-5 text-[1.5rem] font-extrabold text-white uppercase">features</h2>
        <article className="features">
          <section className="charts">
            <h3>24 Live Charts</h3>
            Stable chart reflection with updated mechanism. Varrious data being provided.
          </section>
          <section className="security">
            <h3>Leading Security</h3>
            Tighter security measures were installed to better protect wallet of users from cyber
            attacks.
          </section>
          <section className="platform">
            <h3>
              Easy Futures
              <br />
              Platform
            </h3>
            The convenience of Vibex that people who have experienced User-Authenticated platform.
          </section>
          <section className="support">
            <h3>24/7 Support</h3>
            Got a problem? Just get in touch. Our support team is available 24/7.
          </section>
        </article>
      </div>
      <div className="trading">
        <h2 className="mt-5 text-[1.5rem] font-extrabold text-white uppercase">trading</h2>
        <article className="trading">
          <section>
            <h2>BTC/USDT</h2>
            <h3>A Bitcoin Perpetual with up to 100x leverage, no expiry and low fees!</h3>
            <a href="/bid">View Trading</a>
          </section>
          <section>
            <h2>BTC Option</h2>
            <h3>But and Sell Time interval-style Options</h3>
            <a href="/option/trade">View Trading</a>
          </section>
        </article>
      </div>
      <div className="contracts">
        <h2 className="mt-5 text-[1.5rem] font-extrabold text-white uppercase">contracts</h2>
        <article className="contracts">
          <h3>
            Vibex offers a variety of contract types. All contracts are bought and paid out in
            Bitcoin. Vibex created the 'Perpetual Contract', a high leverage contract that never
            expired!
          </h3>
        </article>
      </div>
        */}

      <Footer />
    </div>
  );
};

export default Home;
