import React, { useState, useCallback } from "react";
import Header from "../components/ui/Header";
import { Desktop, Mobile } from "../hooks/mediaQuery";
import Aside from "../components/ui/Aside";
import "../assets/css/Login.css";
import axios from "axios";
import ToastPopUp from "../components/ui/ToastPopUp";
import AlertPopUp from "../components/ui/AlertPopUp";

const Findpass = () => {
  const hostUrl = process.env.REACT_APP_HOST_URL;

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [userid, setUserid] = useState("");
  const [bscAddress, setBscAddress] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isGoLogin, setIsGoLogin] = useState<boolean>(false);
  // AlertPopUp
  const [isOpenAlertPopUp, setIsOpenAlertPopUp] = useState(false);
  const [alertPopUpDesc, setAlertPopUpDesc] = useState<string>("");
  // ToastPopUp
  const [toastPopUpHidden, setToastPopUpHidden] = useState<string>("hidden");
  const [toastPopUpDesc, setToastPopUpDesc] = useState<string>("");

  const useridChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    // Use a regular expression to allow only English letters
    const regex = /^[A-Za-z0-9!@#$%^&*(),.?":{}|<>]*$/;

    if (regex.test(inputValue)) {
      setUserid(inputValue); // No error here
    }
  };

  const addressChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setBscAddress(event.target.value);
  };

  const passwordChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setPassword(event.target.value);
  };

  const confirmPasswordChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setConfirmPassword(event.target.value);
  };

  const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      doChangePassword();
    }
  };

  function goRegister() {
    window.location.href = "/register";
  }

  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  const showAlertPopup = (popupDesc: string) => {
    setAlertPopUpDesc(popupDesc);
    setIsOpenAlertPopUp(true);
  };

  const hideAlertPopup = () => {
    setIsOpenAlertPopUp(false);
    setAlertPopUpDesc("");
    if (isGoLogin) {
        window.location.replace("/login");
    }
  };

  const showPopup = (popupDesc: string) => {
    setToastPopUpHidden("");
    setToastPopUpDesc(popupDesc);
    hidePopup();
  };

  const hidePopup = () => {
    setTimeout(function () {
      setToastPopUpHidden("hidden");
      setToastPopUpDesc("");
    }, 2000);
  };

  const doChangePassword = () => {
    if (userid.length === 0) {
      showAlertPopup("Please enter your UserID.");
      return;
    }
    let pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
    if (!pattern.test(userid)) {
      showAlertPopup("Please Input your ID as an email.");
      return;
    }
    if (bscAddress.length === 0) {
      showAlertPopup("Please enter your USDT (BEP-20) Address.");
      return;
    }
    if (password.length === 0) {
      showAlertPopup("Please enter your new password.");
      return;
    }
    if (confirmPassword.length === 0) {
      showAlertPopup("Please enter your new confirm password.");
      return;
    }
    if (password !== confirmPassword) {
      showAlertPopup("Passwords do not match");
      return;
    }
    requestChangePassword();
  };

  const requestChangePassword = async (): Promise<void> => {
    try {
      const response = await axios.post(hostUrl + "/auth/changePassword", {
        userid,
        bscAddress,
        password
      });
      if (response.data.code == 0) {
        setIsGoLogin(true);
        showAlertPopup("Your password has been changed.");
      } else {
        showAlertPopup(response.data.message)
      }
    } catch (error) {
      showAlertPopup("Please verify your username or password");
    }
  };

  return (
    <div className="w-full h-full">
      <Header openMenu={openMenu} closeMenu={closeMenu} />
      <Mobile>
        <Aside isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
      </Mobile>
      <section className="flex flex-row justify-center w-full h-full bg-white">
        <div className="login">
          <h1>Find Password</h1>
          <div className="px-10 mt-5">
            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-black">
              UserID
            </label>
            <input
              type="text"
              autoComplete="off"
              className="block w-full p-2.5 text-sm text-[var(--t-color-point)] rounded-lg focus:ring-[var(--t-color-point)] focus:border-[var(--t-color-point)] border border-gray-300"
              placeholder="UserID"
              value={userid}
              onChange={useridChange}
              required
            />
          </div>
          <div className="px-10 mt-5">
            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-black">
              USDT (BEP-20) Address
            </label>
            <input
              type="text"
              autoComplete="off"
              className="block w-full p-2.5 text-sm text-[var(--t-color-point)] rounded-lg focus:ring-[var(--t-color-point)] focus:border-[var(--t-color-point)] border border-gray-300"
              placeholder="USDT (BEP-20) Address"
              value={bscAddress}
              onChange={addressChange}
              required
            />
          </div>
          <div className="w-full h-[1px] my-5 bg-gray-200"></div>
          <div className="px-10 mt-5">
            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-black">
              New Password
            </label>
            <input
              type="password"
              autoComplete="off"
              className="block w-full p-2.5 text-sm text-[var(--t-color-point)] rounded-lg focus:ring-[var(--t-color-point)] focus:border-[var(--t-color-point)] border border-gray-300"
              placeholder="Password"
              value={password}
              onChange={passwordChange}
              required
            />
          </div>
          <div className="px-10 mt-5">
            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-black">
              New Confirm Password
            </label>
            <input
              type="password"
              autoComplete="off"
              className="block w-full p-2.5 text-sm text-[var(--t-color-point)] rounded-lg focus:ring-[var(--t-color-point)] focus:border-[var(--t-color-point)] border border-gray-300"
              placeholder="Password"
              value={confirmPassword}
              onChange={confirmPasswordChange}
              onKeyDown={handleEnterKeyPress}
              required
            />
          </div>
          <div className="px-10 mt-10">
            <button
              className="w-full h-[50px] bg-[var(--t-color-point)] text-lg text-white rounded-lg"
              onClick={doChangePassword}
            >
              Change Password
            </button>
          </div>
        </div>
        {isOpenAlertPopUp && <AlertPopUp message={alertPopUpDesc} onClose={hideAlertPopup} />}
      </section>
      <div className="flex flex-row justify-center w-full h-auto">
        <ToastPopUp hidden={toastPopUpHidden} desc={toastPopUpDesc} />
      </div>
    </div>
  );
};

export default Findpass;
