import { useEffect, useState } from "react";
import { Desktop, Mobile } from "../hooks/mediaQuery";
import Aside from "../components/ui/Aside";
import Header from "../components/ui/Header";
import "../assets/css/Common.css";
import CustomSelect from "../components/ui/CustomSelect";
import WithdrawList from "../components/ui/WithdrawList";
import axios from "axios";
import { useParams } from "react-router-dom";
import TransferHistoryList from "../components/ui/TransferHistoryList";


const TransferHistory = () => {
  const hostUrl = process.env.REACT_APP_HOST_URL;

  const { type } = useParams<{ type: string }>();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [transfers, setTransfers] = useState([]);

  useEffect(() => {
    requestTransferLookup();
  }, []);
  
  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

    /**
   * api
   */
    const requestTransferLookup = async (): Promise<void> => {
      var accessToken = localStorage.getItem("accessToken");
      var requestUrl = hostUrl + "/transfer/lookup?type=" + type;
      try {
        const response = await axios.get(requestUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        ////console.log(response);
        if (response.data.code === 0) {
            setTransfers(response.data.data.transfers);
        }
      } catch (error) {}
    };

  return (
    <div className="w-full h-full">
      <Header openMenu={openMenu} closeMenu={closeMenu} />
      <Mobile>
        <Aside isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
      </Mobile>
      <section className="w-full h-full">
        <div className="content-top content-padding">
          <h1 className="font-bold text-xl text-white">Transfer History</h1>
          {/*
            List
            */}
          <section className="mt-5 pb-5">
            <TransferHistoryList transferHistories={transfers} />
          </section>
        </div>
      </section>
    </div>
  );
};

export default TransferHistory;
