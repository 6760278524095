import React from "react";

type CustomPaginationProps = {
  curPage: number;
  endPage: number;
  existNextPage: boolean;
  existPrevPage: boolean;
  onPageChange: (page: number) => void;
};

const CustomPagination: React.FC<CustomPaginationProps> = ({
  curPage,
  endPage,
  existNextPage,
  existPrevPage,
  onPageChange,
}) => {
  const handlePrevPage = () => {
    if (existPrevPage && curPage > 1) {
      onPageChange(curPage - 1);
    }
  };

  const handleNextPage = () => {
    if (existNextPage && curPage < endPage) {
      onPageChange(curPage + 1);
    }
  };

  const handlePageClick = (page: number) => {
    onPageChange(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageClick(i)}
          disabled={curPage === i}
          className={`mx-1 px-4 py-2 rounded-md ${
            curPage === i ? "text-[var(--t-color-point)] font-bold" : "text-white"
          }`}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  return (
    <div className="flex justify-center items-center gap-1 text-xs">
      <button
        onClick={handlePrevPage}
        disabled={!existPrevPage}
        className="px-4 py-2 rounded-md bg-gray-300 text-black disabled:opacity-50"
      >
        Previous
      </button>
      {renderPageNumbers()}
      <button
        onClick={handleNextPage}
        disabled={!existNextPage}
        className="px-4 py-2 rounded-md bg-gray-300 text-black disabled:opacity-50"
      >
        Next
      </button>
    </div>
  );
};

export default CustomPagination;
