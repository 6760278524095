import React, { useState } from "react";
import { formatDate, formatNumberWithCommas } from "../common/Utils";

interface Props {
  tradeFutures: any;
}

const TradeFuturesList: React.FC<Props> = ({ tradeFutures }) => {
  const headers = [
    "Time",
    "Symbol",
    "Order",
    "Position",
    "Entry Price",
    "Close Price",
    "Size",
    "Fee",
    "Adjustment",
  ];

  function getOrderPath(orderPath: number): string {
    var resultString: string = "LIMIT";
    if (orderPath === 1) {
      resultString = "LIMIT";
    } else if (orderPath === 2) {
      resultString = "MARKET";
    } else if (orderPath === 3) {
      resultString = "STOP LIMIT";
    } else if (orderPath === 4) {
      resultString = "STOP MARKET";
    } else if (orderPath === 5) {
      resultString = "LIQUIDATION";
    }

    return resultString;
  }

  function getOrderPosition(position: number): string {
    var resultString: string = "BUY";
    if (position === 1) {
      resultString = "BUY";
    } else if (position === 2) {
      resultString = "SELL";
    } else if (position === 3) {
      resultString = "LIQUIDATION";
    } else if (position === 4) {
      resultString = "CLOSE";
    }

    return resultString;
  }

  return (
    <div className="w-full h-auto mt-5 px-2 pb-5">
      <div className="pb-3 overflow-x-auto">
        <table className="min-w-full overflow-hidden">
          <thead>
            <tr className="text-[0.8rem] leading-normal text-gray-400">
              {headers.map((header, index) => (
                <th key={index} className=" min-w-[100px] px-3 py-3 text-center">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-sm font-light text-gray-600">
            {tradeFutures.map((tradeFuture: any, index: number) => (
              <tr key={index} className="text-[0.8rem] text-white">
                <td className="px-3 py-3 text-center">{formatDate(tradeFuture.insertDate)}</td>
                <td className="px-3 py-3 text-center">BTCUSDT</td>
                <td className="px-3 py-3 text-center">{getOrderPath(tradeFuture.orderPath)}</td>
                <td className="px-3 py-3 text-center">{getOrderPosition(tradeFuture.position)}</td>
                <td className="px-3 py-3 text-center">
                  {formatNumberWithCommas(tradeFuture.price)}
                </td>
                <td className="px-3 py-3 text-center">
                  {formatNumberWithCommas(tradeFuture.closePrice)}
                </td>
                <td className="px-3 py-3 text-center">
                  {formatNumberWithCommas(tradeFuture.volume)}
                </td>
                <td className="px-3 py-3 text-center">
                  {tradeFuture.position == 3 || tradeFuture.position == 4
                    ? formatNumberWithCommas(tradeFuture.closeOrderFee)
                    : formatNumberWithCommas(tradeFuture.openOrderFee)}
                </td>
                <td className="px-3 py-3 text-center">
                  {formatNumberWithCommas(tradeFuture.realizedProfit)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TradeFuturesList;
