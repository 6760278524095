import React, { useState } from "react";
import "../../App.css";
import "../../assets/css/Common.css";
import Select from "../component/Select";
import CustomCheckbox from "../component/CustomCheckbox";
import PercentageSlider from "./PercentageSlider";
import { formatNumber } from "../common/Utils";

interface Props {
  changeLeverage: () => void;
  changedBuyType: (buyType: number) => void;
  changeOrderPrice: (orderPrice: number) => void;
  changeAmount: (amount: number) => void;
  changePercent: (amount: number) => void;
  checkPriceMarks: (checked: boolean) => void;
  doLong: () => void;
  doShort: () => void;
  tickData: any;
  asset: any;
  leverage: number;
  maxLong: string;
  maxShort: string;
  maxLongCost: string;
  maxShortCost: string;
  isLoggedIn: boolean;
  isButtonDisabled: boolean;
}

const Trade: React.FC<Props> = ({
  changeLeverage,
  changedBuyType,
  changeOrderPrice,
  changeAmount,
  changePercent,
  checkPriceMarks,
  doLong,
  doShort,
  tickData,
  asset,
  leverage,
  maxLong,
  maxShort,
  maxLongCost,
  maxShortCost,
  isLoggedIn,
  isButtonDisabled,
}) => {
  const [isOpenSelectd, setMenuOpenSelected] = useState<boolean>(true);
  const [user, setUser] = useState<any>(null);
  const [selectedBuyType, setSelectedBuyType] = useState<number>(1);
  const [percent, setPercent] = useState<number>(0);
  const [active, setActive] = useState<boolean>(false);
  const [isAutoPriceMarksChecked, setIsAutoPriceMarksChecked] = useState<boolean>(false);
  const [isTpSlChecked, setIsTpSlChecked] = useState<boolean>(false);
  const [percentSliderValue, setPercentSliderValue] = useState<number>(0);
  const [orderPrice, setOrderPrice] = useState<number>(0);
  const [orderPriceValue, setOrderPriceValue] = useState<string>("");
  const [amount, setAmount] = useState<number>(0);
  const [amountValue, setAmountValue] = useState<string>("");

  const toggleOpenClose = (selectOpenClose: boolean) => {
    setMenuOpenSelected(selectOpenClose);
  };

  const changeBuyType = (buyType: number) => {
    setSelectedBuyType(buyType);
    changedBuyType(buyType);
  };

  const changeButtonClick = (newValue: number) => {
    setSelectedBuyType(newValue);
  };

  const handleAutoPriceMarksCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAutoPriceMarksChecked(event.target.checked);
    checkPriceMarks(event.target.checked);
  };

  const handleTpSlCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsTpSlChecked(event.target.checked);
  };

  const handlePercentSliderChange = (event: Event, newValue: number | number[]) => {
    setPercentSliderValue(newValue as number);
    changePercent(newValue as number);
    calculateAmount(newValue as number);
  };

  const handleOrderPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/,/g, "");
    const regex = /^[0-9\b]+$/; // Only allows numbers and backspace
    if (regex.test(rawValue) || rawValue === "") {
      setOrderPriceValue(formatNumber(rawValue));
      setOrderPrice(parseFloat(rawValue));
    }
  };

  const handleOrderPriceBlur = () => {
    const numericValue = parseFloat(orderPriceValue.replace(/,/g, ""));
    if (numericValue < 0) {
      setOrderPriceValue("0");
      changeOrderPrice(0);
    } else if (isNaN(numericValue)) {
      setOrderPriceValue("");
      changeOrderPrice(0);
    } else {
      setOrderPriceValue(formatNumber(numericValue.toString()));
      changeOrderPrice(numericValue);
    }
  };

  const handleOnlyOrderPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    // 숫자, .만 허용 (숫자 시작, 소수점은 한 번만 허용)
    if (/^[0-9.,]*$/.test(inputValue)) {
      const cleanValue = inputValue.replace(/,/g, ""); // 기존 콤마 제거
      const formattedValue = formatWithComma(cleanValue);
      setOrderPriceValue(formattedValue);
      const numericValue = parseFloat(inputValue.replace(/,/g, ""));
      if (numericValue < 0) {
        changeOrderPrice(0);
      } else if (isNaN(numericValue)) {
        changeOrderPrice(0);
      } else {
        changeOrderPrice(numericValue);
      }
    }
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/,/g, ""); // 콤마 제거
    const regex = /^[0-9\b.]+$/; // 숫자, 백스페이스, 소수점만 허용
    if (regex.test(rawValue) || rawValue === "") {
      setAmountValue(formatNumber(rawValue)); // 포맷팅된 값을 상태에 저장
      setAmount(parseFloat(rawValue)); // 소수점 포함 숫자로 변환 후 저장
    }
  };

  const handleAmountBlur = () => {
    const numericValue = parseFloat(amountValue.replace(/,/g, "")); // 소수점 포함 숫자로 변환
    if (numericValue < 0) {
      setAmountValue("0");
      changeAmount(0);
    } else if (isNaN(numericValue)) {
      setAmountValue("");
      changeAmount(0);
    } else {
      setAmountValue(formatNumber(numericValue.toString())); // 소수점까지 포맷팅
      changeAmount(numericValue);
    }
  };

  const handleOnlyOrderAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    // 숫자, .만 허용 (숫자 시작, 소수점은 한 번만 허용)
    if (/^[0-9.,]*$/.test(inputValue)) {
      const cleanValue = inputValue.replace(/,/g, ""); // 기존 콤마 제거
      const formattedValue = formatWithComma(cleanValue);
      setAmountValue(formattedValue);
      const numericValue = parseFloat(inputValue.replace(/,/g, ""));
      if (numericValue < 0) {
        changeAmount(0);
      } else if (isNaN(numericValue)) {
        changeAmount(0);
      } else {
        changeAmount(numericValue);
      }
    }
  };

  const handleLong = () => {
    doLong();
    setOrderPrice(0);
    setOrderPriceValue("");
    setAmount(0);
    setAmountValue("");
  }

  const handleShort = () => {
    doShort();
    setOrderPrice(0);
    setOrderPriceValue("");
    setAmount(0);
    setAmountValue("");
  }


  const formatNumber = (value: string): string => {
    return parseFloat(value).toFixed(4);
  };

  const calculateAmount = (percent: number = 0): void => {
    let currentPrice: number = 0;
    if (tickData != null) {
      currentPrice = tickData.close;
    }
    let usdtm: number = 0;
    if (asset != null) {
      usdtm = asset.usdtm;
    }

    const calculatedAmount = (usdtm / currentPrice) * (percent / 100) * leverage;
    //console.log(formatNumber(calculatedAmount.toString()));
    setAmountValue(formatNumber(calculatedAmount.toString()));
    setAmount(calculatedAmount);
    changeAmount(calculatedAmount);
  };

  const formatWithComma = (value: string) => {
    const [integerPart, decimalPart] = value.split(".");
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return decimalPart !== undefined ? `${formattedInteger}.${decimalPart}` : formattedInteger;
  };

  function goLogin() {
    window.location.href = "/login";
  }

  return (
    <div className="w-full h-auto pb-5">
      <div
        className="flex flex-row items-center justify-start w-full h-auto mt-2 cursor-pointer"
        onClick={changeLeverage}
      >
        <div>
          <span className="font-bold text-[0.9rem] text-white">Isolated</span>
        </div>
        <div className="ml-2">
          <span className="px-4 py-1 font-bold text-[0.9rem] bg-[var(--t-color-point)] text-white rounded-lg">
            {leverage}x
          </span>
        </div>
        {/*
         <div className="ml-2">
          <span className="font-bold text-[0.9rem] text-[var(--t-color-green)]">50x</span>
        </div>
           <div className="ml-2">
          <span className="font-bold text-[0.9rem] text-[var(--t-color-red)]">50x</span>
        </div>
        */}
      </div>
      {/*
           <div className="w-full h-[30px] mt-3 bg-[var(--t-color-darkgray)] rounded-xl">
        <button
          className={`w-[50%] h-full text-sm text-white rounded-l-xl ${
            isOpenSelectd ? "bg-[var(--t-color-green)]" : ""
          }`}
          onClick={() => toggleOpenClose(true)}
        >
          Long
        </button>
        <button
          className={`w-[50%] h-full text-sm text-white rounded-r-xl ${
            !isOpenSelectd ? "bg-[var(--t-color-red)]" : ""
          }`}
          onClick={() => toggleOpenClose(false)}
        >
          Short
        </button>
      </div>
        */}
      <div className="flex flex-row justify-start items-start w-full h-[40px] mt-1 border-b border-gray-700">
        <div
          className={`flex flex-row items-center justify-center w-auto h-full cursor-pointer ${
            selectedBuyType === 1 ? "border-b-2 border-white" : ""
          }`}
          onClick={() => changeBuyType(1)}
        >
          <span
            className={`text-[0.9rem] font-normal ${
              selectedBuyType === 1 ? "text-white" : "text-[var(--t-color-unselected-color)]"
            }`}
          >
            Limit
          </span>
        </div>
        <div
          className={`flex flex-row items-center justify-center w-auto h-full ml-2 cursor-pointer ${
            selectedBuyType === 2 ? "border-b-2 border-white" : ""
          }`}
          onClick={() => changeBuyType(2)}
        >
          <span
            className={`ml-1 text-[0.9rem] font-normal ${
              selectedBuyType === 2 ? "text-white" : "text-[var(--t-color-unselected-color)]"
            }`}
          >
            Market
          </span>
        </div>
      </div>
      {selectedBuyType == 1 && (
        <div className="flex flex-row justify-end mt-3">
          <CustomCheckbox
            checked={isAutoPriceMarksChecked}
            onChange={handleAutoPriceMarksCheckboxChange}
            label="Auto Price Marks"
          />
        </div>
      )}
      <div className="mt-3">
        {selectedBuyType == 1 && (
          <div className="mt-1">
            <h1 className="text-sm text-white">Price (USDT)</h1>
            <div className="flex items-center mt-1">
              {isAutoPriceMarksChecked && tickData != null && (
                <input
                  type="text"
                  className="w-full px-2 py-2 border-2 border-gray-400 focus:border-white outline-none p-0.5 bg-[var(--t-color-darkgray)] text-white rounded-lg"
                  placeholder="Order price"
                  value={formatNumber(tickData?.close)}
                  onChange={handleOrderPriceChange}
                  onBlur={handleOrderPriceBlur}
                  readOnly
                />
              )}
              {!isAutoPriceMarksChecked && (
                <input
                  type="text"
                  className="w-full px-2 py-2 border-2 border-gray-400 focus:border-white outline-none p-0.5 bg-[var(--t-color-darkgray)] text-white rounded-lg"
                  placeholder="Order price"
                  value={orderPriceValue}
                  onChange={handleOnlyOrderPriceChange}
                />
              )}
            </div>
          </div>
        )}
        <div className="mt-2">
          <h1 className="text-sm text-white">Amount</h1>
          <div className="relative flex items-center w-full mt-1">
            <input
              type="text"
              className="w-full pl-2 pr-[100px] py-2 border-2 border-gray-400 focus:border-white outline-none p-0.5 bg-[var(--t-color-darkgray)] text-white rounded-lg"
              placeholder="Amount"
              value={amountValue}
              onChange={handleOnlyOrderAmountChange}
            />
            <span className="absolute right-0 mr-3 text-sm text-white">BTC</span>
          </div>
        </div>
        <div className="relative flex flex-col items-center justify-center w-full bg-black">
          <div className="relative w-full">
            <PercentageSlider value={percentSliderValue} onChange={handlePercentSliderChange} />
          </div>
        </div>
        <div className="py-1 mt-3">
          {/*
              <h3 className="text-[0.8rem] text-[var(--t-color-gray-400)]">
                Avail. USDT (BEP-20): <span className="text-white">0.00000000</span>
              </h3>
            */}
        </div>
        <div className="flex flex-row items-center justify-between py-1">
          <div className="flex flex-col items-start justify-center">
            <h3 className="text-[0.8rem] text-white">Max Long</h3>
            <h3 className="text-[0.8rem] text-[var(--t-color-green)]">{maxLong}</h3>
          </div>
          <div className="flex flex-col items-start justify-center">
            <h3 className="text-[0.8rem] text-white">Max Short</h3>
            <h3 className="text-[0.8rem] text-[var(--t-color-red)]">{maxShort}</h3>
          </div>
        </div>
        <div className="w-full mt-3 border border-gray-700"></div>
        {/*
           <div className="mt-3">
          <CustomCheckbox
            checked={isTpSlChecked}
            onChange={handleTpSlCheckboxChange}
            label="TP/SL"
          />
        </div>
          */}

        {!isLoggedIn && (
          <div className="px-1 mt-3">
            <button
              className="w-full h-[40px] bg-[var(--t-color-point)] text-lg text-white rounded-3xl"
              onClick={goLogin}
            >
              Log In
            </button>
          </div>
        )}
        {isLoggedIn && (
          <div className="flex flex-row items-center justify-between mt-3">
            <button
              className="w-[48%] h-full py-2 bg-[var(--t-color-green)] text-sm text-white rounded-xl"
              onClick={handleLong}
              disabled={isButtonDisabled}
            >
              Open Long
            </button>
            <button
              className="w-[48%] h-full py-2 bg-[var(--t-color-red)] text-sm text-white rounded-xl"
              onClick={handleShort}
              disabled={isButtonDisabled}
            >
              Open Short
            </button>
          </div>
        )}
        <div className="flex flex-row items-center justify-between mt-3">
          <h3 className="text-[0.8rem] text-[var(--t-color-gray-400)]">
            Cost: <span className="text-white">{maxLongCost}</span>
          </h3>
          <h3 className="text-[0.8rem] text-[var(--t-color-gray-400)]">
            Cost: <span className="text-white">{maxShortCost}</span>
          </h3>
        </div>
        <div className="flex flex-row items-center justify-between">
          <h3 className="text-[0.8rem] text-[var(--t-color-gray-400)]">
            Max price: <span className="text-white">{maxLong}</span>
          </h3>
          <h3 className="text-[0.8rem] text-[var(--t-color-gray-400)]">
            Min price: <span className="text-white">{maxShort}</span>
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Trade;
