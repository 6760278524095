import React, { useState } from "react";
import { getPNL, getPNLPercentage, truncateToFourDecimals } from "../common/Utils";

interface ClosePositionProps {
  isOpen: boolean;
  tickData?: any;
  position: any;
  onClose: () => void;
  onConfirm: (amount: number) => void;
}

const ClosePosition: React.FC<ClosePositionProps> = ({
  isOpen,
  tickData,
  position,
  onClose,
  onConfirm,
}) => {
  const [amount, setAmount] = useState(0);

  const handleSetTo = (percentage: number) => {
    const maxAmount = position.volume || 1;
    const calculatedAmount = (percentage / 100) * maxAmount;
    // 반올림 없이 소수점 4자리까지만 유지
    const truncatedAmount = truncateToFourDecimals(calculatedAmount);
    setAmount(truncatedAmount);
  };

  const handleConfirm = () => {
    //console.log(`Confirmed amount: ${amount}`);
    setAmount(0);
    onConfirm(amount);
    onClose();
  };

  const handleClose = () => {
    setAmount(0);
    onClose();
  };

  if (!isOpen) return null;

  var pnl = 0;
  var pnlPercent = 0;
  if (position != null) {
    pnl = getPNL(position.position, tickData?.close, position.averagePrice, position.volume);
    pnlPercent = getPNLPercentage(
      position.position,
      tickData.close,
      position.averagePrice,
      position.volume,
      position.margin
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
      <div className="bg-[var(--t-color-darkgray)] text-white p-6 rounded-lg shadow-lg relative w-[90%] sm:w-full max-w-md">
        <div className="flex justify-between items-center">
          <h1 className="font-bold text-base text-white">Close Position</h1>
          {/* Close Button */}
          <button className="text-gray-400 hover:text-white text-lg" onClick={handleClose}>
            &#x2573;
          </button>
        </div>
        {/* Modal Content */}
        <div className="flex flex-row justify-between items-center mt-5">
          <div className="flex flex-row space-x-1 justify-start items-center">
            <h1 className="tesxt-white">BTCUSDT</h1>
            <h1 className="text-xs">Perp</h1>
            <h1 className="text-xs">{position.leverage}X</h1>
          </div>
        </div>
        <div className="flex flex-row justify-between items-center mt-5">
          <span className="text-gray-400">Entry Price</span>
          <span className="text-white">{position.averagePrice}</span>
        </div>
        <div className="flex flex-row justify-between items-center mt-1">
          <span className="text-gray-400">Mark Price</span>
          <span className="text-white">{tickData?.close}</span>
        </div>

        <h1 className="text-gray-300 font-semibold mt-4 mb-2">Price (Mark)</h1>
        <div className="w-full mb-4 px-2 py-2 border-2 border-gray-400 focus:border-white outline-none p-0.5 bg-[var(--t-color-darkgray)] text-white rounded-lg">
          Market Price
        </div>

        <h1 className="text-gray-300 font-semibold mb-2">Amount</h1>
        <div className="w-full mb-4 px-2 py-2 border-2 border-gray-400 focus:border-white outline-none p-0.5 bg-[var(--t-color-darkgray)] text-white rounded-lg">
          {amount}
        </div>

        {/* Percentage Buttons */}
        <section className="flex gap-2 mb-4">
          {[25, 50, 75, 100].map((percent) => (
            <button
              key={percent}
              className="w-full px-4 py-2 bg-gray-700 hover:bg-gray-600 text-sm rounded text-white"
              onClick={() => handleSetTo(percent)}
            >
              {percent}%
            </button>
          ))}
        </section>

        <div className="flex flex-row justify-between items-center mt-1">
          <span className="text-gray-400">Position Amount</span>
          <span className="text-white">{position.volume} BTC</span>
        </div>
        <div className="flex flex-row justify-between items-center mt-1">
          <span className="text-gray-400">Estimated PNL</span>
          <span
            className={`${
              pnlPercent >= 0
                ? "text-green-500" // 양수일 때 초록색
                : "text-red-500" // 음수일 때 빨간색 기본색
            }`}
          >
            {pnl.toFixed(2)}({pnlPercent.toFixed(2)})
          </span>
        </div>

        <h3 className="mt-3 text-center text-sm text-red-500 mb-4">
          Executing that order will cancel all Waiting orders.
        </h3>

        {/* Confirm Button */}
        <button
          className="w-full mt-5 py-2 text-white border border-[var(--t-color-point)] hover:bg-[var(--t-color-point)] rounded-xl"
          onClick={handleConfirm}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default ClosePosition;
