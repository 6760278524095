import React, { useState, useCallback, useEffect } from "react";
import Header from "../components/ui/Header";
import { Desktop, Mobile } from "../hooks/mediaQuery";
import Aside from "../components/ui/Aside";
import "../assets/css/Register.css";
import ToastPopUp from "../components/ui/ToastPopUp";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Http2ServerResponse } from "http2";

const Register = () => {
  const { code } = useParams();

  const hostUrl = process.env.REACT_APP_HOST_URL;

  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState<number>(1);
  //
  const [isMenuOpen, setMenuOpen] = useState(false);
  // userod
  const [userid, setUserid] = useState("");
  // password
  const [password, setPassword] = useState("");
  // confirmPassword
  const [confirmPassword, setConfirmPassword] = useState("");
  // name
  const [name, setName] = useState("");
  // phone
  const [phone, setPhone] = useState("");
  // ReferralCode
  const [referralCode, setReferralCode] = useState("");
  // images
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [verificationImage, setVerificationImage] = useState<string[]>([]);
  // ToastPopUp
  const [toastPopUpHidden, setToastPopUpHidden] = useState<string>("hidden");
  const [toastPopUpDesc, setToastPopUpDesc] = useState<string>("");

  ////console.log(code);

  useEffect(() => {
    if (code != undefined) {
      setReferralCode(code);
    }
  }, []);

  const useridChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    // Use a regular expression to allow only English letters
    const regex = /^[A-Za-z0-9!@#$%^&*(),.?":{}|<>]*$/;
    if (regex.test(inputValue)) {
      setUserid(inputValue); // No error here
    }
  };

  const passwordChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setPassword(event.target.value);
  };

  const confirmPasswordChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setConfirmPassword(event.target.value);
  };

  const nameChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    const inputValue = event.target.value as string;
    // 영어와 숫자만 허용하는 정규식
    const validValue = inputValue.replace(/[^a-zA-Z0-9]/g, "");
    setName(validValue);
  };

  const phoneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setPhone(formatPhoneNumber(value));
  };

  // Function to format the input as a phone number
  const formatPhoneNumber = (input: string): string => {
    // Remove all non-digit characters from the input
    const cleaned = input.replace(/\D/g, "");

    // Match the cleaned number into parts using regex
    const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);

    // If match found, format the number
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }

    // Return original input if no match found (handle unexpected input gracefully)
    return input;
  };

  const referralCodeChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setReferralCode(event.target.value);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedImage(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  function goLogin() {
    window.location.href = "/login";
  }

  const showPopup = (popupDesc: string) => {
    setToastPopUpHidden("");
    setToastPopUpDesc(popupDesc);
    hidePopup();
  };

  const hidePopup = () => {
    setTimeout(function () {
      setToastPopUpHidden("hidden");
      setToastPopUpDesc("");
    }, 2000);
  };

  const doNext = () => {
    if (userid.length === 0) {
      showPopup("Please enter your email.");
      return;
    }
    let pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
    if (!pattern.test(userid)) {
      showPopup("Please Input your ID as an email.");
      return;
    }
    if (password.length === 0) {
      showPopup("Please enter your password.");
      return;
    }
    if (confirmPassword.length === 0) {
      showPopup("Please enter your confirm password.");
      return;
    }
    if (password !== confirmPassword) {
      showPopup("Passwords do not match");
      return;
    }
    if (name.length === 0) {
      showPopup("Please enter your name.");
      return;
    }
    if (phone.length === 0) {
      showPopup("Please enter your phone number.");
      return;
    }
    /*
    if (referralCode.length === 0) {
      showPopup("Please enter your referral code.");
      return;
    }
    */
  
    setStep(2);
  };

  const doRegister = () => {
    if (!selectedImage) {
      alert("Please select an image before uploading.");
      return;
    }
    setIsLoading(true);
    requestUploadImage();
  };

  const requestUploadImage = async () => {
    if (selectedImage != null) {
      const formData = new FormData();
      formData.append("uploadFile", selectedImage);
      try {
        const response = await axios.post(hostUrl + "/upload/images", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        ////console.log(response);
        if (response.data.data.images.length > 0) {
          setVerificationImage(response.data.data.images[0]);
        }
        requestRegister(response.data.data.images[0]);
      } catch (e) {
        setSelectedImage(null);
        setPreviewImage(null);
        setIsLoading(false);
      }
    }
  };

  const requestRegister = async (verificationImage: string): Promise<void> => {
    try {
      const response = await axios.post(hostUrl + "/auth/register", {
        userid,
        password,
        name,
        phone,
        referralCode,
        verificationImage: verificationImage
      });
      if (response.status === 200) {
        if (response.data.code === 0) {
          showPopup("Successfully registered.");
          setTimeout(function () {
            window.location.href = "/login";
          }, 1000);
        } else {
          showPopup(response.data.message);
        }
      } else {
        showPopup("Failed to register.");
      }
      setIsLoading(false);
      //window.location.replace("/login");
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full h-full">
      <Header openMenu={openMenu} closeMenu={closeMenu} />
      <Mobile>
        <Aside isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
      </Mobile>
      <section className="flex flex-row justify-center w-full h-full bg-white">
        <div className="register">
          <h1>Welcome to Vibex</h1>
          {step == 1 && (
            <section>
              <div className="px-10 mt-5">
                <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-black">
                  Email (UserID)
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  className="block w-full p-2.5 text-sm text-[var(--t-color-point)] rounded-lg focus:ring-[var(--t-color-point)] focus:border-[var(--t-color-point)] border border-gray-300"
                  placeholder="Email"
                  value={userid}
                  onChange={useridChange}
                  required
                />
              </div>
              <div className="px-10 mt-5">
                <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-black">
                  Password
                </label>
                <input
                  type="password"
                  autoComplete="off"
                  className="block w-full p-2.5 text-sm text-[var(--t-color-point)] rounded-lg focus:ring-[var(--t-color-point)] focus:border-[var(--t-color-point)] border border-gray-300"
                  placeholder="Password"
                  value={password}
                  onChange={passwordChange}
                  required
                />
              </div>
              <div className="px-10 mt-5">
                <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-black">
                  Confirm Password
                </label>
                <input
                  type="password"
                  autoComplete="off"
                  className="block w-full p-2.5 text-sm text-[var(--t-color-point)] rounded-lg focus:ring-[var(--t-color-point)] focus:border-[var(--t-color-point)] border border-gray-300"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={confirmPasswordChange}
                  required
                />
              </div>
              <div className="px-10 mt-5">
                <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-black">
                  Name
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  className="block w-full p-2.5 text-sm text-[var(--t-color-point)] rounded-lg focus:ring-[var(--t-color-point)] focus:border-[var(--t-color-point)] border border-gray-300"
                  placeholder="Name"
                  value={name}
                  onChange={nameChange}
                  required
                />
              </div>
              <div className="px-10 mt-5">
                <label htmlFor="phone" className="block mb-2 text-sm font-medium text-black">
                  Phone
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  className="block w-full p-2.5 text-sm text-[var(--t-color-point)] rounded-lg focus:ring-[var(--t-color-point)] focus:border-[var(--t-color-point)] border border-gray-300"
                  placeholder="Phone"
                  value={phone}
                  onChange={phoneChange}
                  required
                />
              </div>
              <div className="px-10 mt-5">
                {/*
            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-black">
              Referral Code
            </label>
              */}
                <input
                  type="hidden"
                  autoComplete="off"
                  className="block w-full p-2.5 text-sm text-[var(--t-color-point)] rounded-lg focus:ring-[var(--t-color-point)] focus:border-[var(--t-color-point)] border border-gray-300"
                  placeholder="Referral Code"
                  value={referralCode}
                  onChange={referralCodeChange}
                  required
                />
              </div>
              <div className="px-10 mt-10">
                <button
                  className="w-full h-[50px] bg-[var(--t-color-point)] text-lg text-white rounded-lg"
                  onClick={doNext}
                >
                  Next
                </button>
              </div>
            </section>
          )}

          {step == 2 && (
            <section>
              <div className="flex flex-col justify-start items-center max-w-[600px] mt-5 px-4 py-10 mx-auto b">
                <h2 className="font-bold text-base text-black mb-4">KYC Photo Verification</h2>
                <p className="text-gray-600 mb-8 text-left">
                  To complete your identity verification, please upload a photo of a valid ID.
                  Ensure the image is clear and the information is readable.
                </p>

                <div className="w-full bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
                  <div className="w-full mb-4">
                    <label
                      htmlFor="fileInput"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Select an Image
                    </label>
                    <input
                      id="fileInput"
                      type="file"
                      accept="image/jpeg, image/png"
                      className="w-full border border-gray-300 rounded-lg p-2 text-sm focus:outline-none"
                      onChange={handleImageChange}
                    />
                  </div>

                  {previewImage && (
                    <div className="mb-4">
                      <p className="text-sm text-gray-600 mb-2">Selected Image:</p>
                      <img
                        src={previewImage}
                        alt="Preview"
                        className="w-32 h-32 object-cover rounded-lg border"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="px-10 mt-10">
                <button
                  className="w-full h-[50px] bg-[var(--t-color-point)] text-lg text-white rounded-lg"
                  disabled={isLoading} 
                  onClick={doRegister}
                >
                  Create Account
                </button>
              </div>
            </section>
          )}

          <h3 className="mt-5 text-sm text-center" onClick={goLogin}>
            Already have an account?
            <span className="ml-1 font-bold text-[var(--t-color-point)] cursor-pointer">
              Log In
            </span>
          </h3>
        </div>
      </section>
      <div className="flex flex-row justify-center w-full h-auto">
        <ToastPopUp hidden={toastPopUpHidden} desc={toastPopUpDesc} />
      </div>
    </div>
  );
};

export default Register;
