import React, { useState, useCallback } from "react";
import Header from "../components/ui/Header";
import { Desktop, Mobile } from "../hooks/mediaQuery";
import Aside from "../components/ui/Aside";
import "../assets/css/Login.css";
import axios from "axios";
import ToastPopUp from "../components/ui/ToastPopUp";
import AlertPopUp from "../components/ui/AlertPopUp";

const Findid = () => {
  const hostUrl = process.env.REACT_APP_HOST_URL;

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [email, setEmaul] = useState("");
  const [phone, setPhone] = useState("");
  // AlertPopUp
  const [isOpenAlertPopUp, setIsOpenAlertPopUp] = useState(false);
  const [alertPopUpDesc, setAlertPopUpDesc] = useState<string>("");
  // ToastPopUp
  const [toastPopUpHidden, setToastPopUpHidden] = useState<string>("hidden");
  const [toastPopUpDesc, setToastPopUpDesc] = useState<string>("");

  const emailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setEmaul(inputValue);
  };

  const phoneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setPhone(formatPhoneNumber(value));
  };

  const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      doFindid();
    }
  };

  // Function to format the input as a phone number
  const formatPhoneNumber = (input: string): string => {
    // Remove all non-digit characters from the input
    const cleaned = input.replace(/\D/g, "");

    // Match the cleaned number into parts using regex
    const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);

    // If match found, format the number
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }

    // Return original input if no match found (handle unexpected input gracefully)
    return input;
  };

  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  const showAlertPopup = (popupDesc: string) => {
    setAlertPopUpDesc(popupDesc);
    setIsOpenAlertPopUp(true);
  };

  const hideAlertPopup = () => {
    setIsOpenAlertPopUp(false);
    setAlertPopUpDesc("");
  };

  const showPopup = (popupDesc: string) => {
    setToastPopUpHidden("");
    setToastPopUpDesc(popupDesc);
    hidePopup();
  };

  const hidePopup = () => {
    setTimeout(function () {
      setToastPopUpHidden("hidden");
      setToastPopUpDesc("");
    }, 2000);
  };

  const doFindid = () => {
    if (email.length === 0) {
      showAlertPopup("Please enter your email.");
      return;
    }
    let pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
    if (!pattern.test(email)) {
      showAlertPopup("Not an email pattern.");
      return;
    }
    if (phone.length === 0) {
      showAlertPopup("Please enter your phone.");
      return;
    }
    requestFindid();
  };

  const requestFindid = async (): Promise<void> => {
    try {
      const response = await axios.post(hostUrl + "/auth/findid", {
        email,
        phone,
      });
      ////console.log(response);
      if (response.data.code == 0) {
        showAlertPopup("Your User ID is " + response.data.data);
      } else {
        showAlertPopup(response.data.message)
      }
    } catch (error) {
      showAlertPopup("Please verify your email or phone");
    }
  };

  return (
    <div className="w-full h-full">
      <Header openMenu={openMenu} closeMenu={closeMenu} />
      <Mobile>
        <Aside isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
      </Mobile>
      <section className="flex flex-row justify-center w-full h-full bg-white">
        <div className="login">
          <h1>Find ID</h1>
          <div className="px-10 mt-5">
            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-black">
              Email
            </label>
            <input
              type="text"
              autoComplete="off"
              className="block w-full p-2.5 text-sm text-[var(--t-color-point)] rounded-lg focus:ring-[var(--t-color-point)] focus:border-[var(--t-color-point)] border border-gray-300"
              placeholder="Email"
              value={email}
              onChange={emailChange}
              required
            />
          </div>
          <div className="px-10 mt-5">
            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-black">
              Phone
            </label>
            <input
              type="text"
              autoComplete="off"
              className="block w-full p-2.5 text-sm text-[var(--t-color-point)] rounded-lg focus:ring-[var(--t-color-point)] focus:border-[var(--t-color-point)] border border-gray-300"
              placeholder="Phone"
              value={phone}
              onChange={phoneChange}
              onKeyDown={handleEnterKeyPress}
              required
            />
          </div>
          <div className="px-10 mt-10">
            <button
              className="w-full h-[50px] bg-[var(--t-color-point)] text-lg text-white rounded-lg"
              onClick={doFindid}
            >
              Find ID
            </button>
          </div>
        </div>
        {isOpenAlertPopUp && <AlertPopUp message={alertPopUpDesc} onClose={hideAlertPopup} />}
      </section>
      <div className="flex flex-row justify-center w-full h-auto">
        <ToastPopUp hidden={toastPopUpHidden} desc={toastPopUpDesc} />
      </div>
    </div>
  );
};

export default Findid;
