import React, { useState, useCallback, useEffect } from "react";
import "../../assets/css/Manager.css";
import HeaderManager from "../../components/ui/HeaderManager";
import axios from "axios";
import { formatDate, formatNumberWithCommas } from "../../components/common/Utils";
import { useNavigate } from "react-router-dom";

const ManagerTradingHistory = () => {
  const navigate = useNavigate();

  const hostUrl = process.env.REACT_APP_HOST_URL;

  const [isSelectedOptions, setIsSelectedOptions] = useState(true);
  const [data, setData] = useState<any>(null);
  const [optionOrders, setOptionOrders] = useState<any>(null);

  useEffect(() => {
    const level = localStorage.getItem("level");
    let levelNumber: number = 1;
    if (level != null) {
      levelNumber = parseInt(level);
    }
    if (levelNumber < 2) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    requestManagerTradingLookup();
  }, []);

  function getResult(result: number): string {
    var resultString: string = "WAIT";
    if (result === 0) {
      resultString = "WAIT";
    } else if (result === 1) {
      resultString = "HIGHER";
    } else if (result === 2) {
      resultString = "LOWER";
    } else if (result === 3) {
      resultString = "EQ";
    }

    return resultString;
  }

  /**
   * api
   */
  const requestManagerTradingLookup = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/manager/trading";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      ////console.log(response);
      if (response.data.code === 0) {
        setData(response.data.data);
        ////console.log(response.data.data.optionOrders);
        setOptionOrders(response.data.data.optionOrders);
      }
    } catch (error) {}
  };

  return (
    <div className="w-full h-full">
      <section className="w-full h-full manager">
        <HeaderManager />
        <section className="main">
          <h1 data-lang="title_settlement">Trading History</h1>
          <section className="search">
            <div className="form">
              <select id="search_text_type">
                <option value="name" data-lang="opt_name">
                  Name
                </option>
                <option value="uid" data-lang="opt_id">
                  ID
                </option>
              </select>
              <input type="text" id="search_text" placeholder="Input" value="" />
              <button>Search</button>
              {/*
                <div className="history"></div>
              */}
            </div>
          </section>

          <section className="history">
            <section className="tab">
              <button className={isSelectedOptions ? "selected" : ""}>Option</button>
              {/*
              <button className={isSelectedOptions ? "" : "selected"}>Futures</button>
                */}
            </section>

            {isSelectedOptions && (
              <section>
                <div className="summary">
                  <div>
                    <h2>
                      <span data-lang="trading_revenue">Total User Revenue</span>
                      {data ? formatNumberWithCommas(data?.totalUserRevenue) : "0"}
                    </h2>
                    <h3>Options</h3>
                  </div>
                  <div>
                    <h2>
                      <span data-lang="trading_loss">Total User Loss</span>
                      {data ? formatNumberWithCommas(data?.totalUserLoss) : "0"}
                    </h2>
                    <h3>Options</h3>
                  </div>
                  <div>
                    <h2>
                      <span data-lang="trading_pnl">Total User PNL</span>
                      {data ? formatNumberWithCommas(data?.totalUserPNL) : "0"}
                    </h2>
                    <h3>Options</h3>
                  </div>
                </div>

                <table>
                  <thead>
                    <tr>
                      <th data-lang="table_date">Date</th>
                      <th data-lang="table_name">Name</th>
                      <th>UID</th>
                      <th>Symbol</th>
                      <th>Type</th>
                      <th>Size</th>
                      <th>Open Price</th>
                      <th>Result Price</th>
                      <th>Bet Type</th>
                      <th>Result</th>
                      <th>PNL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {optionOrders?.map((optionOrder: any, index: number) => (
                      <tr key={index} className="text-[0.8rem] text-white">
                        <td>{formatDate(optionOrder?.insertDate)}</td>
                        <td>{optionOrder?.userid}</td>
                        <td>{optionOrder?.userName}</td>
                        <td>BTCUSDT</td>
                        <td>1 Minute</td>
                        <td>{formatNumberWithCommas(optionOrder?.volume)}</td>
                        <td>{formatNumberWithCommas(optionOrder?.openPrice)}</td>
                        <td>{formatNumberWithCommas(optionOrder?.resultPrice)}</td>
                        <td>{optionOrder?.betType === 1 ? "HIGHER" : "LOWER"}</td>
                        <td>{getResult(optionOrder?.betType)}</td>
                        <td>{formatNumberWithCommas(optionOrder?.settleFee)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </section>
            )}
          </section>
        </section>
      </section>
    </div>
  );
};

export default ManagerTradingHistory;
