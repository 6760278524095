import React, { useState } from "react";
import { formatDate, formatNumberWithCommas, getPNL, getPNLPercentage } from "../common/Utils";
import { useMediaQuery } from "react-responsive";

interface Props {
  orders: any;
  positions: any;
  openOrders: any;
  futureHistories: any;
  tickData: any;
  closePositionClick: () => void;
  tpslClick: () => void;
  cancelOrderClick: (orderNo: number) => void;
}

const TradeAccount: React.FC<Props> = ({
  orders,
  positions,
  openOrders,
  futureHistories,
  tickData,
  closePositionClick,
  tpslClick,
  cancelOrderClick,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  const positionHeaders = [
    "Symbol",
    "Size",
    "Enty Price",
    "Mark Price",
    "Liq. Price",
    "Risk Ratio",
    "Margin",
    "Realized PnL | %",
    "Close",
    "TP/SL",
  ];

  const openOrderHeaders = [
    "Symbol",
    "Time",
    "Type",
    "Size",
    "Price",
    "Amount",
    "Reduce Only",
    "Post Only",
    "Cancel",
  ];

  const futhreHistoriesHeaders = [
    "Time",
    "Symbol",
    "Order",
    "Position",
    "Entry Price",
    "Close Price",
    "Size",
    "Fee",
    "Adjustment",
  ];

  function getOrderPath(orderPath: number): string {
    var resultString: string = "LIMIT";
    if (orderPath === 1) {
      resultString = "LIMIT";
    } else if (orderPath === 2) {
      resultString = "MARKET";
    } else if (orderPath === 3) {
      resultString = "STOP LIMIT";
    } else if (orderPath === 4) {
      resultString = "STOP MARKET";
    } else if (orderPath === 5) {
      resultString = "LIQUIDATION";
    }

    return resultString;
  }

  function getOrderPosition(position: number): string {
    var resultString: string = "BUY";
    if (position === 1) {
      resultString = "BUY";
    } else if (position === 2) {
      resultString = "SELL";
    } else if (position === 3) {
      resultString = "LIQUIDATION";
    } else if (position === 4) {
      resultString = "CLOSE";
    }

    return resultString;
  }

  return (
    <div className="w-full h-auto px-2">
      <div className="flex flex-row justify-start items-start w-full h-[40px] border-b border-gray-700">
        <div
          className={`flex flex-row items-center justify-center w-auto h-full ${
            selectedTabIndex == 0 ? "border-b-2 border-white" : ""
          }`}
          onClick={() => setSelectedTabIndex(0)}
        >
          <span className="text-[0.9rem] font-normal text-white">
            Positions({positions.length})
          </span>
        </div>
        <div
          className={`flex flex-row items-center justify-center w-auto h-full ml-3 ${
            selectedTabIndex == 1 ? "border-b-2 border-white" : ""
          }`}
          onClick={() => setSelectedTabIndex(1)}
        >
          {" "}
          <span className="text-[0.9rem] font-normal text-white">
            Open Orders({openOrders.length})
          </span>
        </div>
        <div
          className={`flex flex-row items-center justify-center w-auto h-full ml-3 ${
            selectedTabIndex == 2 ? "border-b-2 border-white" : ""
          }`}
          onClick={() => setSelectedTabIndex(2)}
        >
          {" "}
          <span className="text-[0.9rem] font-normal text-white">Order History</span>
        </div>
      </div>
      {selectedTabIndex == 0 && isDesktop && (
        <div className="pb-3 overflow-x-auto">
          <table className="min-w-full overflow-hidden">
            <thead>
              <tr className="text-[0.8rem] leading-normal text-gray-400">
                {positionHeaders.map((header, index) => (
                  <th key={index} className=" min-w-[100px] px-3 py-3 text-center">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="text-sm font-light text-gray-600">
              {positions.map((position: any, index: number) => (
                <tr key={index} className="h-auto text-[0.8rem] text-white">
                  <td className={`px-4 py-2 relative`}>
                    <div className="w-full h-[40px] flex flex-row space-x-2">
                      <div
                        className={`w-1 h-full  ${
                          position.position === 1 ? "bg-green-500" : "bg-red-500"
                        }`}
                      />
                      <div className="flex flex-col">
                        <h1 className="tesxt-white">BTCUSDT</h1>
                        <div className="flex flex-row space-x-2 justify-start items-center">
                          <h1 className="text-xs">Perp</h1>
                          <h1 className="text-sx">{position.leverage}X</h1>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="relative px-4 py-2 text-center">
                    <span
                      className={`${position.position === 1 ? "text-green-500" : "text-red-500"}`}
                    >
                      {formatNumberWithCommas(position.volume)} BTC
                    </span>
                  </td>
                  <td className="relative px-4 py-2 text-center">
                    {formatNumberWithCommas(position.averagePrice)}
                  </td>
                  <td className="relative px-4 py-2 text-center">
                    {formatNumberWithCommas(tickData?.close)}
                  </td>
                  <td className="relative px-4 py-2 text-center text-red-400">
                    {formatNumberWithCommas(position.liquidationPrice)}
                  </td>
                  <td className="relative px-4 py-2 text-center">
                    {getRiskRate(position, tickData?.close).toFixed(2)}
                  </td>
                  <td className="relative px-4 py-2 text-center">
                    {formatNumberWithCommas(position.margin)}
                  </td>
                  <td className="relative px-4 py-2 text-center">
                    <div
                      className={`flex flex-col ${
                        getPNL(
                          position.position,
                          tickData?.close,
                          position.averagePrice,
                          position.volume
                        ) >= 0
                          ? "text-green-500" // 양수일 때 초록색
                          : "text-red-500" // 음수일 때 빨간색 기본색
                      }`}
                    >
                      <span>
                        {formatNumberWithCommas(
                          getPNL(
                            position.position,
                            tickData?.close,
                            position.averagePrice,
                            position.volume
                          )
                        )}
                      </span>
                      <span className="ml-1">
                        (
                        {getPNLPercentage(
                          position.position,
                          tickData?.close,
                          position.averagePrice,
                          position.volume,
                          position.margin
                        ).toFixed(2)}
                        %)
                      </span>
                    </div>
                  </td>
                  <td className="relative px-4 py-2 text-center">
                    <button
                      className="w-[100px] py-2 bg-[var(--t-color-darkgray)] border border-gray-600 hover:border-white text-sm text-white rounded-xl"
                      onClick={() => closePositionClick()}
                    >
                      Market
                    </button>
                  </td>
                  <td className="relatice px-4 py-2 text-center">
                    <div className="flex items-center justify-center">
                      <span className="mr-2">
                        {position.takeProfit && position.stopLoss
                          ? `${position.takeProfit} / ${position.stopLoss}`
                          : "-- / --"}
                      </span>
                      <button
                        className="text-gray-500 hover:text-white"
                        onClick={() => tpslClick()}
                      >
                        ✏️
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {selectedTabIndex == 0 && isMobile && (
        <div className="w-full h-auto py-5">
          {positions.map((position: any, index: number) => (
            <div key={index} className="flex flex-col space-y-1 text-white">
              <div className="flex justify-between">
                <div className="w-full h-auto flex flex-row space-x-2 text-xs">
                  <div
                    className={`w-1 h-full  ${
                      position.position === 1 ? "bg-green-500" : "bg-red-500"
                    }`}
                  />
                  <div className="flex flex-row space-x-1 justify-start items-center">
                    <h1 className="tesxt-white">BTCUSDT</h1>
                    <h1 className="text-xs">Perp</h1>
                    <h1 className="text-sx">{position.leverage}X</h1>
                  </div>
                </div>
              </div>
              <div className="flex justify-between text-xs pt-2">
                <span className="text-gray-400">Size</span>
                <span className={`${position.position === 1 ? "text-green-500" : "text-red-500"}`}>
                  {formatNumberWithCommas(position.volume)} BTC
                </span>
              </div>
              <div className="flex justify-between text-xs pt-2">
                <span className="text-gray-400">Entry Price</span>
                <span className="">{formatNumberWithCommas(position.averagePrice)}</span>
              </div>
              <div className="flex justify-between text-xs pt-2">
                <span className="text-gray-400">Mark Price</span>
                <span className="">{formatNumberWithCommas(tickData?.close)}</span>
              </div>
              <div className="flex justify-between text-xs pt-2">
                <span className="text-gray-400">Liq. Price</span>
                <span className="text-red-400">
                  {formatNumberWithCommas(position.liquidationPrice)}
                </span>
              </div>
              <div className="flex justify-between text-xs pt-2">
                <span className="text-gray-400"> Risk Ratio</span>
                <span className="">{getRiskRate(position, tickData?.close).toFixed(2)}</span>
              </div>
              <div className="flex justify-between text-xs pt-2">
                <span className="text-gray-400">Margin</span>
                <span className="">{formatNumberWithCommas(position.margin)}</span>
              </div>
              <div className="flex justify-between text-xs pt-2">
                <span className="text-gray-400">Realized PnL | %</span>
                <span className="">
                  <div
                    className={`flex flex-row ${
                      getPNLPercentage(
                        position.position,
                        tickData?.close,
                        position.averagePrice,
                        position.volume,
                        position.margin
                      ) >= 0
                        ? "text-green-500" // 양수일 때 초록색
                        : "text-red-500" // 음수일 때 빨간색 기본색
                    }`}
                  >
                    <span>
                      {formatNumberWithCommas(
                        getPNL(
                          position.position,
                          tickData?.close,
                          position.averagePrice,
                          position.volume
                        )
                      )}
                    </span>
                    <span className="ml-1">
                      (
                      {getPNLPercentage(
                        position.position,
                        tickData?.close,
                        position.averagePrice,
                        position.volume,
                        position.margin
                      ).toFixed(2)}
                      %)
                    </span>
                  </div>
                </span>
              </div>
              <div className="flex justify-between text-xs pt-2">
                <span className="text-gray-400">TP/SL</span>
                <div className="flex items-center justify-center">
                  <span className="mr-2">
                    {position.takeProfit && position.stopLoss
                      ? `${position.takeProfit} / ${position.stopLoss}`
                      : "-- / --"}
                  </span>
                  <button className="text-gray-500 hover:text-white" onClick={() => tpslClick()}>
                    ✏️
                  </button>
                </div>
              </div>
            </div>
          ))}
          <button
            className="w-full mx-auto mt-5 py-2 bg-[var(--t-color-darkgray)] border border-gray-600 hover:border-white text-sm text-white rounded-xl"
            onClick={() => closePositionClick()}
          >
            Close Position
          </button>
        </div>
      )}
      {selectedTabIndex == 1 && (
        <div className="w-full overflow-x-auto">
          <table className="min-w-full overflow-hidden">
            <thead>
              <tr className="text-[0.8rem] leading-normal text-gray-400">
                {openOrderHeaders.map((header, index) => (
                  <th key={index} className=" min-w-[100px] px-3 py-3 text-center">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="text-sm font-light text-gray-600">
              {openOrders.map((openOrder: any, index: number) => (
                <tr key={index} className="text-[0.8rem] text-white">
                  <td className={`px-4 py-2 relative`}>
                    <div className="w-full h-[40px] flex flex-row space-x-2">
                      <div
                        className={`w-1 h-full  ${
                          openOrder.position === 1 ? "bg-green-500" : "bg-red-500"
                        }`}
                      />
                      <div className="flex flex-col">
                        <h1 className="tesxt-white">BTCUSDT</h1>
                        <div className="flex flex-row space-x-2 justify-start items-center">
                          <h1 className="text-xs">Perp</h1>
                          <h1 className="text-sx">{openOrder.leverage}X</h1>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="relative px-4 py-2 text-center">
                    {formatDate(openOrder.insertDate)}
                  </td>
                  <td className="relative px-4 py-2 text-center">
                    Limit / {openOrder.position === 1 ? "Long" : "Short"}
                  </td>
                  <td className="relative px-4 py-2 text-center">
                    <span
                      className={`${openOrder.position === 1 ? "text-green-500" : "text-red-500"}`}
                    >
                      {formatNumberWithCommas(openOrder.volume)} BTC
                    </span>
                  </td>
                  <td className="relative px-4 py-2 text-center">
                    {formatNumberWithCommas(openOrder.price)}
                  </td>
                  <td className="relative px-4 py-2 text-center">
                    {formatNumberWithCommas(openOrder.volume)}
                  </td>
                  <td className="relative px-4 py-2 text-center">NO</td>
                  <td className="relative px-4 py-2 text-center">NO</td>
                  <td className="relative px-4 py-2 text-center">
                    <button
                      className="w-[100px] py-2 bg-[var(--t-color-darkgray)] border border-gray-600 hover:border-white text-sm text-white rounded-xl"
                      onClick={() => cancelOrderClick(openOrder.no)}
                    >
                      Cancel
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {selectedTabIndex == 2 && (
        <div className="w-full overflow-x-auto">
          <table className="min-w-full overflow-hidden">
            <thead>
              <tr className="text-[0.8rem] leading-normal text-gray-400">
                {futhreHistoriesHeaders.map((header, index) => (
                  <th key={index} className=" min-w-[100px] px-3 py-3 text-center">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="text-sm font-light text-gray-600">
              {futureHistories.map((tradeFuture: any, index: number) => (
                <tr key={index} className="text-[0.8rem] text-white">
                  <td className="px-3 py-3 text-center">{formatDate(tradeFuture.insertDate)}</td>
                  <td className="px-3 py-3 text-center">BTCUSDT</td>
                  <td className="px-3 py-3 text-center">{getOrderPath(tradeFuture.orderPath)}</td>
                  <td className="px-3 py-3 text-center">
                    {getOrderPosition(tradeFuture.position)}
                  </td>
                  <td className="px-3 py-3 text-center">
                    {formatNumberWithCommas(tradeFuture.price)}
                  </td>
                  <td className="px-3 py-3 text-center">
                    {formatNumberWithCommas(tradeFuture.closePrice)}
                  </td>
                  <td className="px-3 py-3 text-center">
                    {formatNumberWithCommas(tradeFuture.volume)}
                  </td>
                  <td className="px-3 py-3 text-center">
                    {tradeFuture.position == 3 || tradeFuture.position == 4
                      ? formatNumberWithCommas(tradeFuture.closeOrderFee)
                      : formatNumberWithCommas(tradeFuture.openOrderFee)}
                  </td>
                  <td className="px-3 py-3 text-center">
                    {formatNumberWithCommas(tradeFuture.realizedProfit)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const getRiskRate = (positionData: any, tick: number): number => {
  let riskRate = 0;

  if (positionData.position === 1) {
    // Long 포지션
    if (tick >= positionData.averagePrice) {
      riskRate = 0;
    } else {
      riskRate =
        ((positionData.averagePrice - tick) /
          (positionData.averagePrice - positionData.liquidationPrice)) *
        100;
    }
  } else if (positionData.position === 2) {
    // Short 포지션
    if (tick <= positionData.averagePrice) {
      riskRate = 0;
    } else {
      riskRate =
        ((positionData.averagePrice - tick) /
          (positionData.averagePrice - positionData.liquidationPrice)) *
        100;
    }
  }

  return riskRate;
};

export default TradeAccount;
