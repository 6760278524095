import React, { useEffect, useState } from "react";
import {
  getPNL,
  getPNLPercentage,
  truncateToFourDecimals,
  truncateToTwoDecimals,
} from "../common/Utils";

interface PositionTpslProps {
  isOpen: boolean;
  tickData?: any;
  position: any;
  onClose: () => void;
  onConfirm: (takeProfit: number, stopLoss: number) => void;
}

const PositionTpsl: React.FC<PositionTpslProps> = ({
  isOpen,
  tickData,
  position,
  onClose,
  onConfirm,
}) => {
  const [takeProfit, setTakeProfit] = useState<string>("");
  const [stopLoss, setStopLoss] = useState<string>("");
  const [tpProfit, setTpProfit] = useState<number>(0);
  const [slProfit, setSlProfit] = useState<number>(0);

  // Take Profit Calculation
  useEffect(() => {
    if (position && takeProfit) {
      const tpValue = parseFloat(takeProfit.replace(/,/g, ""));
      if (isNaN(tpValue) || tpValue <= 0) {
        setTpProfit(0); // Reset profit if invalid input
        return;
      }

      let unrealizedProfit = 0;
      if (position.position === 1) {
        // 매수
        unrealizedProfit = position.volume * (tpValue - position.averagePrice);
      } else if (position.position === 2) {
        // 매도
        unrealizedProfit = position.volume * (position.averagePrice - tpValue);
      }
      const marketCloseFee: number = 0.0004;
      const fee = tpValue * position.volume * marketCloseFee;
      unrealizedProfit -= fee;

      setTpProfit(unrealizedProfit);
    }
  }, [takeProfit, position]);

  // Stop Loss Calculation
  useEffect(() => {
    if (position && stopLoss) {
      const slValue = parseFloat(stopLoss);
      if (isNaN(slValue) || slValue <= 0) {
        setSlProfit(0); // Reset profit if invalid input
        return;
      }

      let unrealizedProfit = 0;

      if (position.position === 1) {
        // 매수
        unrealizedProfit = position.volume * (slValue - position.averagePrice);
      } else if (position.position === 2) {
        // 매도
        unrealizedProfit = position.volume * (position.averagePrice - slValue);
      }

      const marketCloseFee: number = 0.0004;
      const fee = slValue * position.volume * marketCloseFee;
      unrealizedProfit -= fee;

      setSlProfit(unrealizedProfit);
    }
  }, [stopLoss, position]);

  // 숫자와 소수점만 입력 허용
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setValue: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const rawValue = e.target.value.replace(/,/g, ""); // Remove existing commas
    const regex = /^[0-9]*\.?[0-9]*$/; // Allow only numbers and one decimal point

    if (regex.test(rawValue)) {
      setValue(formatNumber(rawValue)); // Format the input value with commas
    }
  };

  // 입력값에 콤마 추가 (소수점 아래는 콤마를 붙이지 않음)
  const formatNumber = (value: string): string => {
    if (!value) return "";
    const [integerPart, decimalPart] = value.split("."); // Split by decimal point
    const formattedInteger = parseInt(integerPart || "0", 10).toLocaleString("en-US"); // Add commas to integer part
    return decimalPart !== undefined
      ? `${formattedInteger}.${decimalPart}` // Keep the decimal part without commas
      : formattedInteger;
  };

  // 확인 버튼 클릭 시 호출
  const handleConfirm = () => {
    const takeProfitValue = parseFloat(takeProfit.replace(/,/g, ""));
    const stopLossValue = parseFloat(stopLoss.replace(/,/g, ""));
    // onConfirm 호출
    onConfirm(takeProfitValue, stopLossValue);
    setTakeProfit("");
    setStopLoss("");
    onClose();
  };

  const handleClose = () => {
    setTakeProfit("");
    setStopLoss("");
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
     <div className="bg-[var(--t-color-darkgray)] text-white p-6 rounded-lg shadow-lg relative w-[90%] sm:w-full max-w-md">
        <div className="flex justify-between items-center">
          <h1 className="font-bold text-base text-white">Position TP/SL</h1>
          {/* Close Button */}
          <button className="text-gray-400 hover:text-white text-lg" onClick={handleClose}>
            &#x2573;
          </button>
        </div>
        {/* Modal Content */}
        <div className="flex flex-row justify-between items-center mt-5">
          <div className="flex flex-row space-x-1 justify-start items-center">
            <h1 className="tesxt-white">BTCUSDT</h1>
            <h1 className="text-xs">Perp</h1>
            <h1 className="text-xs">{position.leverage}X</h1>
          </div>
        </div>
        <div className="flex flex-row justify-between items-center mt-5">
          <span className="text-gray-400">Entry Price</span>
          <span className="text-white">{position.averagePrice}</span>
        </div>
        <div className="flex flex-row justify-between items-center mt-1">
          <span className="text-gray-400">Mark Price</span>
          <span className="text-white">{tickData?.close}</span>
        </div>

        <label className="block mt-4 mb-2 text-white font-semibold">Take Profit</label>
        <input
          type="text"
          value={takeProfit}
          onChange={(e) => handleInputChange(e, setTakeProfit)}
          placeholder="Enter take profit"
          className="w-full px-2 py-2 border-2 border-gray-400 focus:border-white outline-none p-0.5 bg-[var(--t-color-darkgray)] text-white rounded-lg"
        />
        <h3 className="mt-3 text-sm text-gray-500 mb-4">
          When <span className="text-white">Mark Price</span> reaches{" "}
          <span className="text-white">{takeProfit}</span>, it will trigger Take Profit Marker order
          to close this position. Estimated PNL will be{" "}
          <span className={`${tpProfit >= 0 ? "text-green-500" : "text-red-500"}`}>
            {truncateToTwoDecimals(tpProfit)}
          </span>{" "}
          USDT.
        </h3>

        <label className="block mt-4 mb-2 text-white font-semibold">Stop Loss</label>
        <input
          type="text"
          value={stopLoss}
          onChange={(e) => handleInputChange(e, setStopLoss)}
          placeholder="Enter stop loss"
          className="w-full px-2 py-2 border-2 border-gray-400 focus:border-white outline-none p-0.5 bg-[var(--t-color-darkgray)] text-white rounded-lg"
        />
        <h3 className="mt-3 text-sm text-gray-500 mb-4">
          When <span className="text-white">Mark Price</span> reaches{" "}
          <span className="text-white">{stopLoss}</span>, it will trigger Stop Market order to close this
          position. Estimated PNL will be{" "}
          <span className={`${slProfit >= 0 ? "text-green-500" : "text-red-500"}`}>
            {truncateToTwoDecimals(slProfit)}
          </span>{" "}
          USDT.
        </h3>

        <h3 className="mt-3 text-center text-sm text-red-500 mb-4">
          All waiting orders will be canceled when that order is executed.
        </h3>

        {/* Confirm Button */}
        <button
          className="w-full mt-5 py-2 text-white border border-[var(--t-color-point)] hover:bg-[var(--t-color-point)] rounded-xl"
          onClick={handleConfirm}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default PositionTpsl;
