import { Route, Routes } from "react-router-dom";
import "@fontsource/montserrat"; // Defaults to weight 400
import "@fontsource/montserrat/400.css"; // Specify weight
import "@fontsource/montserrat/400-italic.css"; // Specify weight and style
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Manager from "./pages/Manager";
import Futures from "./pages/Futures";
import Options from "./pages/Options";
import Assets from "./pages/Assets";
import Wallet from "./pages/Wallet";
import Deposit from "./pages/Deposit";
import Withdraw from "./pages/Withdraw";
import Transfer from "./pages/Transfer";
import Partner from "./pages/manager/Partner";
import DepositHistory from "./pages/DepositHistory";
import WithdrawHistory from "./pages/WithdrawHistory";
import TransferFutures from "./pages/TransferFutures";
import TradeHistory from "./pages/TradeHistory";
import Faq from "./pages/Faq";
import TransferHistory from "./pages/TransferHistory";
import Members from "./pages/manager/Members";
import SettlementHistory from "./pages/manager/SettlementHistory";
import ManagerWithdraw from "./pages/manager/ManagerWithdraw";
import ManagerTradingHistory from "./pages/manager/ManagerTradingHistory";
import { Helmet } from "react-helmet-async";
import Profile from "./pages/Profile";
import Markets from "./pages/Markets";
import Earn from "./pages/Earn";
import PrivateRoute from "./components/component/PrivateRoute";
import UnderConstruction from "./pages/UnderConstruction";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Legal from "./pages/Legal";
import Fee from "./pages/Fee";
import Findid from "./pages/Findid";
import Findpass from "./pages/Findpass";


function App() {
  return (
    <div className="w-full h-full">
      <Helmet>
        <title>Vibex</title>
      </Helmet>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/legal" element={<Legal />} />
        <Route path="/fee" element={<Fee />} />
        <Route path="/register" element={<Register />} />
        <Route path="/register/:code" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/findid" element={<Findid />} />
        <Route path="/findpass" element={<Findpass />} />
        <Route path="/profile" element={<PrivateRoute component={Profile} path={""} />} />
        <Route path="/futures" element={<Futures />} />
        <Route path="/options" element={<UnderConstruction />} />
        {
          /*
          <Route path="/options" element={<Options />} />
          */
        }
        <Route path="/wallet" element={<PrivateRoute component={Wallet} path={""} />} />
        <Route path="/deposit" element={<PrivateRoute component={Deposit} path={""} />} />
        <Route path="/deposit/history" element={<PrivateRoute component={DepositHistory} path={""} />} />
        <Route path="/withdraw" element={<PrivateRoute component={Withdraw} path={""} />} />
        <Route path="/withdraw/history" element={<PrivateRoute component={WithdrawHistory} path={""} />} />
        {
          /*
           <Route path="/transfer" element={<Transfer />} />
          */
        }        
        <Route path="/transfer/history/:type" element={<PrivateRoute component={TransferHistory} path={""} />} />
        <Route path="/transfer/futures" element={<PrivateRoute component={TransferFutures} path={""} />} />
        <Route path="/trade/history" element={<PrivateRoute component={TradeHistory} path={""} />} />
        <Route path="/markets" element={<Markets />} />
        <Route path="/earn" element={<UnderConstruction />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/affiliates" element={<PrivateRoute component={Manager} path={""} />} />
        <Route path="/affiliates/partner" element={<PrivateRoute component={Partner} path={""} />} />
        <Route path="/affiliates/members" element={<PrivateRoute component={Members} path={""} />} />
        <Route path="/affiliates/trading" element={<PrivateRoute component={ManagerTradingHistory} path={""} />} />
        <Route path="/affiliates/history" element={<PrivateRoute component={SettlementHistory} path={""} />} />
        <Route path="/affiliates/withdraw" element={<PrivateRoute component={ManagerWithdraw} path={""} />} />
      </Routes>
    </div>
  );
}

export default App;
