import React, { useState } from "react";
import { formatNumber } from "../common/Utils";

interface Props {
  asset: any;
  position: any;
  isOption: boolean;
}

const Assets: React.FC<Props> = ({ asset, position, isOption }) => {
  if (!asset) {
    return <div></div>;
  }

  function goDeposit() {
    window.location.href = "/deposit";
  }

  function goWithdraw() {
    window.location.href = "/withdraw";
  }

  const getAvailOrder = (): string => {
    var availOrder = 0;
    if (asset) {
      const balance = asset?.usdtm;
      const btc = asset?.btc;
      const usdt = asset?.usdt;
      const ura = asset?.usingRequiredAmount;
      const lwra = asset?.longWaitingRequiredAmount;
      const swra = asset?.shortWaitingRequiredAmount;

      var availOrder = 0;
      if (position != null) {
        if (position?.position == 1) {
          availOrder = balance - ura - lwra;
        } else if (position?.position == 2) {
          availOrder = balance - ura - swra;
        }
      } else {
        var minus = 0;
        if (lwra > swra) {
          minus = lwra;
        }
        if (swra > lwra) {
          minus = swra;
        }
        availOrder = balance - minus;
      }
    }

    return availOrder.toString();
  };

  const getBtc = (): string => {
    var btcBalance = 0;
    if (asset) {
      const btc = asset?.btc;
      btcBalance = btc;
    }

    return btcBalance.toString();
  };

  const getBsc = (): string => {
    var bscBalance = 0;
    if (asset) {
      const bsc = asset?.bsc;
      bscBalance = bsc;
    }

    return bscBalance.toString();
  };

  const getUsdt = (): string => {
    var usdtBalance = 0;
    if (asset) {
      const usdt = asset?.usdt;
      usdtBalance = usdt;
    }

    return usdtBalance.toString();
  };

  const getUsdtm = (): string => {
    var usdtBalance = 0;
    if (asset) {
      const usdtm = asset?.usdtm;
      usdtBalance = usdtm;
    }

    return usdtBalance.toString();
  };

  return (
    <div className="w-full h-auto pb-3">
      <h1 className="mt-3 text-sm font-semibold text-white">Assets</h1>
      <div className="flex flex-row items-center justify-between mt-5">
        <button
          className="w-[48%] h-full py-2 bg-[var(--t-color-darkgray)] border border-gray-600 hover:border-white text-sm text-white rounded-xl"
          onClick={goDeposit}
        >
          Deposit
        </button>
        <button
          className="w-[48%] h-full py-2 bg-[var(--t-color-darkgray)] border border-gray-600 hover:border-white text-sm text-white rounded-xl"
          onClick={goWithdraw}
        >
          Withdraw
        </button>
      </div>
      <div className="mt-5 text-[0.7rem] text-white">
        {isOption && (
          <div>
            <div className="flex flex-row items-center justify-between">
              <h1>Available for Order</h1>
              <h1>{formatNumber(getUsdtm())}</h1>
            </div>
            <div className="flex flex-row items-center justify-between mt-1">
              <h1>USDT (BEP-20)</h1>
              <h1>{formatNumber(getBsc())}</h1>
            </div>
            <div className="flex flex-row items-center justify-between mt-1">
              <h1>USDT-Futures</h1>
              <h1>{formatNumber(asset?.usdtm.toString())}</h1>
            </div>
          </div>
        )}
        {!isOption && (
          <div>
            <div className="flex flex-row items-center justify-between">
              <h1>USDT-Futures</h1>
              <h1>{formatNumber(asset?.usdtm.toString())}</h1>
            </div>
            <div className="flex flex-row items-center justify-between mt-1">
              <h1>Available for Order</h1>
              <h1>{formatNumber(getAvailOrder())}</h1>
            </div>
            <div className="flex flex-row items-center justify-between mt-1">
              <h1>USDT (BEP-20)</h1>
              <h1>{formatNumber(getBsc())}</h1>
            </div>
          </div>
        )}
        {/*
          <div className="flex flex-row items-center justify-between mt-1">
            <h1>USDT Balance</h1>
            <h1>{formatNumber(getUsdt())} USDT</h1>
          </div>
          */}
      </div>
    </div>
  );
};

export default Assets;
